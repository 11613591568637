import type { UIThemableComponentProps } from '../types/UI';
import { UIShapes, UIShapesExtra, UISizes, UIVariants, UIVariantsExtra } from '../types/UI';

export const colorShades: Array<string> = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'];

type ThemableProps = UIThemableComponentProps & {
  theme?: string;
  disabled?: boolean;
  accented?: boolean;
};

enum Themes {
  Flowbite = 'flowbite',
  Tailwind = 'tailwind',
  Ninja = 'ninja',
}

export const UIStyling = {
  shapes: ({ shape, theme }: ThemableProps) => {
    if (theme === Themes.Flowbite) {
      return {
        [`rounded-lg`]: shape === UIShapes.rounded,
        [`rounded-full`]: shape === UIShapesExtra.pill || shape === UIShapesExtra.circle,
        ['rounded-none']: shape === UIShapes.rect,
      };
    }

    return {
      [`rounded`]: shape === UIShapes.rounded,
      [`rounded-full`]: shape === UIShapesExtra.pill || shape === UIShapesExtra.circle,
      ['rounded-none']: shape === UIShapes.rect,
    };
  },
  textSizes: ({ size, theme }: ThemableProps) => {
    return {
      ['text-xs']: size === UISizes.xs,
      ['text-sm']: size == UISizes.sm,
      ['text-md']: size == UISizes.md,
      ['text-2xl']: size == UISizes.lg,
      ['text-3xl']: size == UISizes.xl,
    };
  },
  squareSizes: ({ size }: ThemableProps) => ({
    [`w-6 h-6`]: size === UISizes.xs,
    [`w-8 h-8`]: size === UISizes.sm,
    [`w-10 h-10`]: size === UISizes.md,
    [`w-20 h-20`]: size === UISizes.lg,
    [`w-36 h-36`]: size === UISizes.xl,
  }),

  // sizes used for checkbox and radio
  squareSizesForm: ({ size, theme }: ThemableProps) => {
    if (theme === Themes.Flowbite) {
      return {
        [`w-4 h-4`]: size === UISizes.xs,
        [`w-5 h-5`]: size === UISizes.sm,
        [`w-6 h-6`]: size === UISizes.md,
        [`w-8 h-8`]: size === UISizes.lg,
        [`w-10 h-10`]: size === UISizes.xl,
      };
    }
    return {
      [`w-3 h-3`]: size === UISizes.xs,
      [`w-5 h-5`]: size === UISizes.sm,
      [`w-6 h-6`]: size === UISizes.md,
      [`w-8 h-8`]: size === UISizes.lg,
      [`w-10 h-10`]: size === UISizes.xl,
    };
  },
  buttonPaddings: ({ size, theme }: ThemableProps) => {
    if (theme === Themes.Flowbite) {
      return {
        [`size-xs px-2 py-1`]: size === UISizes.xs,
        [`size-sm px-3 py-1.5`]: size === UISizes.sm,
        [`size-md px-4 py-2`]: size === UISizes.md,
        [`size-lg px-5 py-2.5`]: size === UISizes.lg,
        [`size-xl px-6 py-3`]: size === UISizes.xl,
      };
    }
    if (theme === Themes.Ninja) {
      return {
        [`size-xs px-1`]: size === UISizes.xs,
        [`size-sm px-1.5`]: size === UISizes.sm,
        [`size-md px-2 py-[1px]`]: size === UISizes.md,
        [`size-lg px-2 py-1`]: size === UISizes.lg,
        [`size-xl px-3 py-1`]: size === UISizes.xl,
      };
    }
    return {
      [`size-xs px-1 py-[2px]`]: size === UISizes.xs,
      [`size-sm px-1 py-1`]: size === UISizes.sm,
      [`size-md px-2 py-1`]: size === UISizes.md,
      [`size-lg px-2 py-1`]: size === UISizes.lg,
      [`size-xl px-2 py-1`]: size === UISizes.xl,
    };
  },
  paddings: ({ size, theme }: ThemableProps) => {
    if (theme === Themes.Flowbite) {
      return {
        [`size-xs px-1 py-[2px]`]: size === UISizes.xs,
        [`size-sm px-2 py-1`]: size === UISizes.sm,
        [`size-md px-2 py-1`]: size === UISizes.md,
        [`size-lg px-2 py-1`]: size === UISizes.lg,
        [`size-xl px-2 py-1`]: size === UISizes.xl,
      };
    }

    return {
      [`size-xs px-1 py-[2px]`]: size === UISizes.xs,
      [`size-sm px-1 py-1`]: size === UISizes.sm,
      [`size-md px-1 py-1`]: size === UISizes.md,
      [`size-lg px-2 py-1`]: size === UISizes.lg,
      [`size-xl px-2 py-1`]: size === UISizes.xl,
    };
  },
  boxPaddings: ({ size }: ThemableProps) => ({
    [`p-2`]: size === UISizes.xs,
    [`p-4`]: size === UISizes.sm,
    [`p-6`]: size === UISizes.md,
    [`p-8`]: size === UISizes.lg,
    [`p-10`]: size === UISizes.xl,
  }),
  elevations: ({ elevation }: ThemableProps) => ({
    [`#333 shadow-sm`]: elevation === 1,
    [`#333 shadow`]: elevation === 2,
    [`#333 shadow-md`]: elevation === 3,
    [`#333 shadow-lg`]: elevation === 4,
    [`#333 shadow-xl`]: elevation === 5,
    [`#333 shadow-2xl`]: elevation === 6,
  }),
  colorShadows: ({ color }: ThemableProps) => ({
    // [`shadow-${color}`]: true,
  }),
  variants: ({ theme, variant, color, gradient }: ThemableProps) => {
    if (theme === Themes.Flowbite) {
      return {
        // Solid components have a background and a border
        [`bg-${color} text-${color}-foreground`]: variant === UIVariants.solid && !gradient,

        // [`text-${color}-foreground bg-gradient-to-r from-${color} via-${color}-hover to-${color}-active `]:
        //   variant === UIVariants.solid && gradient,
        [`text-${color}-foreground dark:text-${color}-300 bg-gradient-to-r from-${color}-500 via-${color}-600 to-${color}-700`]:
          variant === UIVariants.solid && gradient,

        [`border border-transparent`]: variant === UIVariants.solid || variant === UIVariants.ghost,
        [`border border-${color}`]: variant === UIVariants.outline,
        // Outline components only have a border and apply text color
        [`text-${color}`]: variant === UIVariants.outline,

        // Link variants only apply text color
        [`text-${color}`]: variant === UIVariantsExtra.link,

        // Alternative
        [`bg-white border border-${color}-200 hover:bg-${color}-100 focus:z-10 focus:ring-4 focus:ring-${color}-100 dark:focus:ring-${color}-700 dark:bg-${color}-900 dark:text-${color}-400 dark:border-${color}-600 dark:hover:text-white dark:hover:bg-${color}-700`]:
          variant === UIVariantsExtra.alternative,
      };
    }

    return {
      // Solid components have a background and a border
      [`bg-${color} text-${color}-foreground`]: variant === UIVariants.solid && !gradient,
      [`text-${color}-foreground bg-gradient-to-r from-${color} via-${color}-hover to-${color}-active`]:
        variant === UIVariants.solid && gradient,
      [`border border-${color} `]: variant === UIVariants.solid,
      // Outline components only have a border
      [`border border-${color}`]: variant === UIVariants.outline,

      // [`bg-${color} bg-opacity-25`]: variant === UIVariants.transparant,

      // Ghost variants do not have a border or background but still keep the same size, so the border is made transparent
      [`border border-transparent`]: variant === UIVariants.ghost,
      // Link variants only apply text color
      [`text-${color}`]: variant === UIVariantsExtra.link,
    };
  },
  variantsHover: ({ theme, variant, color, gradient, disabled }: ThemableProps) => {
    if (disabled) return {};
    if (theme === Themes.Flowbite) {
      return {
        // Solid components change background color on hover
        [`hover:bg-${color}-hover`]: (variant === UIVariants.solid || variant === UIVariants.ghost) && !gradient,
        [`hover:bg-gradient-to-br`]: (variant === UIVariants.solid || variant === UIVariants.ghost) && gradient,
        // Outline components have a background on hover
        [`hover:bg-${color}-hover hover:text-white`]: variant === UIVariants.outline,
        // Link component change text color on hover
        [`hover:text-${color}-hover`]: variant === UIVariantsExtra.link,
      };
    }
    return {
      // Solid components change background color on hover
      [`hover:bg-${color}-hover`]: (variant === UIVariants.solid || variant === UIVariants.ghost) && !gradient,
      [`hover:bg-gradient-to-br`]: (variant === UIVariants.solid || variant === UIVariants.ghost) && gradient,
      // Link component change text color on hover
      [`hover:text-${color}-hover`]: variant === UIVariantsExtra.link,
    };
  },
  disabled: ({ disabled }: ThemableProps) => ({
    'cursor-not-allowed': disabled,
    'disabled:cursor-not-allowed disabled:opacity-80': true,
  }),
  focusRing: ({ color }: ThemableProps, condition: boolean = true) => ({
    [`ui-focused ring-${color} ring-offset-bg-${color}-foreground ring ring-offset-2`]: condition,
  }),
  focusVisibleRing: ({ color }: ThemableProps) => ({
    [`focus-visible:ring-${color} focus-visible:ring-offset-bg-${color}-foreground focus-visible:ring focus-visible:ring-offset-2`]:
      true,
  }),
};
